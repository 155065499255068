"use client";
import React, { useState, ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { useRouter } from "next/navigation";
import usePreviousSearches from "@hooks/usePreviousSearches";
import Image from "next/image";
import { usePathname } from "next/navigation";
import RadioButton from "@lib/ui/components/RadioButton";
import AdvancedSearchSection from "./AdvancedSearchSection";
import { SearchParams } from "src/interfaces/interfaces";
import ExpandableTextarea from "@lib/ui/components/ExpandableTextarea";

interface SearchLogicHolderProps {
	previousSearch: SearchParams;
	textColor: string;
	outLineColor: string;
	showAdvancedSearch?: boolean;
	hideRadioBtns?: boolean;
}
interface SearchState {
	search: string;
	showSuggestions: boolean;
	author: string;
	journal: string;
	startDate: string;
	endDate: string;
	show: boolean;
	selectedValue: string;
	articleTypes: {
		[key: string]: boolean;
	};
}

function SearchLogicHolder({
	textColor,
	outLineColor,
	previousSearch,	
	showAdvancedSearch = true,
	hideRadioBtns = false,
}: SearchLogicHolderProps) {
	const router = useRouter();
	const pathname = usePathname();
	const isHomePage = pathname === "/";
	const [state, setState] = useState<SearchState>({
		search: previousSearch?.query, // search query
		showSuggestions: false,
		author: previousSearch?.author, // author value if found
		journal: previousSearch?.journal, // journal value if found
		startDate: previousSearch?.startdate,
		endDate: previousSearch?.enddate, // date value if found
		show: false, // show advanced search
		selectedValue: previousSearch?.searchEngine,
		articleTypes: {
			// "Books and Documents": previousSearch["Books and Documents"],
			"Clinical Trial": previousSearch["Clinical Trial"],
			"Meta-Analysis": previousSearch["Meta-Analysis"],
			"Randomized Controlled Trial": previousSearch["Randomized Controlled Trial"],
			Review: previousSearch.Review,
			"Case Report": previousSearch["Case Report"],
			"Primary Article": previousSearch["Primary Article"],
			All: previousSearch.All,
		},
	});

	const [previousSearches, addSearch] = usePreviousSearches("previousSearches", []);
	const [previousAuthorSearches, addAuthorSearch] = usePreviousSearches("previousAuthorSearches", []);
	const [previousJournalSearches, addJournalSearch] = usePreviousSearches("previousJournalSearches", []);
	// const [previousDateSearches, addDateSearch] = usePreviousSearches("previousDateSearches", []);
	const [suggestionListTopPosition, setSuggestionListTopPosition] = useState<number>(68); // Initialize with default value

	// Handle changes for the main search input
	const handleInputFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
		if (state.search !== "") {
			setState((prevState) => ({
				...prevState,
				showSuggestions: true,
				suggestions: previousSearches,
			}));
		}
	};

	const handleSuggestionClick = (suggestion: string) => {
		setState((prevState) => ({
			...prevState,
			search: suggestion,
			showSuggestions: false,
		}));
		addSearch(suggestion);
	};

	const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value;
		setState((prevState) => ({
			...prevState,
			search: value,
			showSuggestions: true,
		}));
	};

	const handleTextAreaKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleFormSubmit();
		}
	};

	const handleFormSubmit = () => {
		const extractYear = (date: string): string | null => {
			if (!date) return null; // Handle empty string or null
			const year = new Date(date).getFullYear();
			return isNaN(year) ? null : year.toString();
		};

		// Check if the search input is a number and has a length between 5 and 15 digits
		const isNumberOnly = /^\d+$/.test(state.search); // Checks if input contains only numbers
		if (isNumberOnly && state.search.length >= 5 && state.search.length <= 15) {
			// Navigate to the custom URL
			router.push(`/article/${state.search}`);
			return;
		}

		// Regular search functionality
		const queryParams = [
			`query:${state.search || "none"}`,
			`author:${state.author || "none"}`,
			`journal:${state.journal || "none"}`,
			`startdate:${extractYear(state.startDate) || "2000"}`,
			`enddate:${extractYear(state.endDate) || new Date().getFullYear().toString()}`,
			`searchEngine:${state.selectedValue}`,
			...Object.keys(state.articleTypes).map((key) => (state.articleTypes[key] ? `${key}:true` : `${key}:false`)),
			`isTop50Journal:false`,
			`hasPdf:false`,
		].join("/");

		if (state.search) addSearch(state.search);
		if (state.author) addAuthorSearch(state.author);
		if (state.journal) addJournalSearch(state.journal);
		router.push(`/search/${queryParams}`);
	};

	const toggleAdvancedSearch = () => {
		setState((prevState) => ({
			...prevState,
			show: !prevState.show,
		}));
	};

	const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState((prevState) => ({
			...prevState,
			selectedValue: event.target.value,
		}));
	};

	const handleCheckboxChange = (label: string) => {
		setState((prevState) => ({
			...prevState,
			articleTypes: {
				...prevState.articleTypes,
				[label]: !prevState.articleTypes[label],
			},
		}));
	};

	const handleTextareaHeightChange = (height: number) => {
		setSuggestionListTopPosition(height + 10); // Add 10px gap
	};
	return (
		<>
			<div
				onMouseLeave={() => setState((prevState) => ({ ...prevState, showSuggestions: false }))}
				className={`relative m-auto flex min-h-fit w-[95%] flex-row items-center gap-2 rounded-lg bg-white p-2 md:px-3 md:py-1 lg:w-full`}
			>
				<span className="my-auto hidden text-2xl text-primaryGray md:block">
					<CiSearch />
				</span>
				<div className="relative inline-block w-full">
					<ExpandableTextarea
						value={state.search}
						onChange={handleTextAreaChange}
						onKeyDown={handleTextAreaKeyDown}
						onFocus={handleInputFocus}
						onHeightChange={handleTextareaHeightChange}
						maxLines={6}
						placeholder="Search..."
						className="placeholder:text-muted-foreground flex w-full text-wrap rounded-md border-0 bg-transparent text-sm text-gray-900 focus:outline-none focus:ring-0 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
					/>
				</div>
				{/* px-3 py-5 pb-0 pt-5 */}
				{state.search && (
					<button
						type="button"
						onClick={() => setState((prevState) => ({ ...prevState, search: "", showSuggestions: false }))}
						className="mr-2 text-2xl text-primaryGray"
					>
						<Image width={20} height={20} alt="cancel icon" src={`/imgs/icons/cancel.svg`} />
					</button>
				)}
				<span
					onClick={handleFormSubmit}
					className="my-auto block cursor-pointer rounded bg-primaryBlue px-6 py-4 text-sm text-white md:hidden"
				>
					Search
				</span>
				<button
					onClick={handleFormSubmit}
					type="submit"
					className="hidden cursor-pointer rounded bg-primaryBlue px-6 py-3 text-white md:block"
				>
					Search
				</button>
				{previousSearches.length > 0 && state.showSuggestions && (
					<ul
						className="absolute bottom-0 left-0 right-0 top-[70px] z-20 h-fit max-h-60 min-h-4 w-full overflow-y-scroll rounded border border-gray-300 bg-white"
						style={{ top: `${suggestionListTopPosition}px` }}
					>
						{previousSearches.map((suggestion: string, index: number) => (
							<li
								key={index}
								onClick={() => handleSuggestionClick(suggestion)}
								className="flex cursor-pointer flex-row items-center gap-2 p-2 px-2 py-2 duration-500 hover:bg-gray-200"
							>
								<Image width={15} height={15} alt="clock icon" src={`/imgs/icons/clock.svg`} />
								<span>{suggestion}</span>
							</li>
						))}
					</ul>
				)}
			</div>
			<div className="m-auto flex h-fit w-[95%] flex-row items-center justify-between gap-2 px-2 pb-5 lg:w-full">
				{/* {showAdvancedSearch && (
					<button onClick={toggleAdvancedSearch} className={`flex flex-row items-center ${textColor} gap-2`}>
						Advanced search
						<IoIosArrowDown className={`text-lg ${state.show ? "rotate-180" : "rotate-0"} transition-transform`} />
					</button>
				)} */}
				{!hideRadioBtns && <div className="ml-auto flex w-fit flex-row items-center gap-2 px-3">
					<div className="flex items-center">
						<RadioButton
							id="radio1"
							label="MedEngine"
							value="MedEngine"
							name="default-radio"
							checked={state.selectedValue === "MedEngine"}
							onChange={handleRadioButtonChange}
							variant={"primary"}
						/>
					</div>
					<div className="flex items-center">
						<RadioButton
							id="radio2"
							label="PubMed"
							value="PubMed"
							name="default-radio"
							checked={state.selectedValue === "PubMed"}
							onChange={handleRadioButtonChange}
							variant={"primary"}
						/>
					</div>
				</div>}
			</div>
			{showAdvancedSearch && state.show && (
				<AdvancedSearchSection
					state={state}
					setState={setState}
					textColor={textColor}
					isHomePage={isHomePage}
					previousAuthorSearches={previousAuthorSearches}
					previousJournalSearches={previousJournalSearches}
					addAuthorSearch={addAuthorSearch}
					addJournalSearch={addJournalSearch}
					handleCheckboxChange={handleCheckboxChange}
				/>
			)}
		</>
	);
}

export default SearchLogicHolder;
